import React, {useState, useEffect, useRef} from "react"
import {stringReplaceMask, phoneDraw, uSearch, sGet, sSet, sDel, ajaxUpdate} from 'helper-func'
import {apiAlert, apiUrl, req} from 'api-methods'
import './App.css'

function App() {

    // 1. Start params
    const PARTNER_OPT                  = {id: 203, lp: 'ru', tl: '+996', lk: 'https://o.kg/'}
    const [errMessage, setErrMessage]  = useState('Подробнее: сервис недоступен')
    const [identity, setIdentity]      = useState(0)                   // ID
    const [pageState, setPageState]    = useState('loader')            // страница (loader) → notFound / loader / insertPhone / insertSms / approveWait
    const [onePopup, setOnePopup]      = useState(false)               // подсказка
    const [addFormTel, setAddFormTel]  = useState(PARTNER_OPT.tl)                // форма-тел
    const [addFormSms, setAddFormSms]  = useState('')                  // форма-смс
    const [addFormSmsCoolDown, setAddFormSmsCoolDown] = useState(60)   // форма-смс-отсчет (60)
    const [addFormSmsLimit, setAddFormSmsLimit] = useState(3)          // форма-смс-попытки (3)
    const [offers, setOffers]          = useState([])                  // предложения
    const [token, setToken]            = useState('')                  // token
    let internalIdentity               = sGet('identity') * 1             // internal identity
    let internalPhone                  = sGet('phone')                    // identity phone
    let internalToken                  = sGet('token')                    // identity token
    let internalCode                   = sGet('code') * 1                 // identity code
    let placeholderSms                 = '_ _ _ _'

    // 2. Internal Redirect (req №5)
    if (pageState === 'loader' && internalIdentity !== 0) {setIdentity(internalIdentity);setPageState('insertPhone')}
    if (pageState === 'insertPhone' && (internalPhone + '').length > 4) {setAddFormTel(internalPhone);setPageState('insertSms')}
    if (pageState === 'insertSms' && (internalToken + '') !== '' && ([-10, -16, -19].indexOf(internalCode) > -1)) {
        setToken(internalToken)
        setPageState('approveWait')
        const serviceProductsList = async () => {
            try {
                let ajPost = await req({method: apiUrl.nProducts, rType: 'GET', noSearch: false, gets: {identity: identity, token: token}})

                if (ajPost.code + '' === '0') {
                    if (!ajPost.products) {
                        apiAlert(200,{message: 'products is empty in answer (nProducts)'}).then()
                    } else {
                        setOffers(ajPost.products)
                    }
                    if (internalCode === -10) {setPageState('ussd')} else {setPageState('link')}
                } else {
                    setErrMessage(ajPost.message)
                    setPageState('error')
                    clearStorage()
                }
            } catch (e) {
                apiAlert(200,{target: 'catch - nProducts', error: e}).then()
                setPageState('error')
                clearStorage()
            }
        }
        serviceProductsList()
    }
    if (identity === 0) {setIdentity(uSearch('identity'));if (pageState === 'loader') {setPageState('insertPhone')}}

    // 8b. Service func
    const insFormTel = (tel, insert = true) => {tel = phoneDraw(stringReplaceMask(tel), 'kz');if (insert) {setAddFormTel(tel)} else {return tel}}

    // 3. Activate Product (req №4)
    const [reqService, setReqService] = useState(false)
    const serviceActivate = async (serviceId) => {
        if (!reqService && (pageState === 'link' || pageState === 'ussd')) {
            setReqService(true)
            setPageState('loader')
            try {
                let ajPost = await req({method: apiUrl.nActivate, rType: 'GET', noSearch: false, gets: {identity: identity, token: token, serviceId: serviceId}})

                clearStorage()
                if (ajPost.code === 0) {
                    if (!ajPost.redirect) {
                        apiAlert(200,{message: 'redirect is empty in answer (nActivate)'}).then()
                        setPageState('error')
                    } else {
                        redirectToUrl(decodeURIComponent(ajPost.redirect))
                    }

                } else {
                    setErrMessage(ajPost.message)
                    setPageState('error')
                }
            } catch (e) {
                apiAlert(200,{target: 'catch - nActivate', error: e}).then()
                setPageState('error')
                clearStorage()
            }
        }
    }

    // 4a. CoolDown SMS
    function useInterval(callback, delay) {
        const savedCallback = useRef()
        useEffect(() => {savedCallback.current = callback}, [callback])
        useEffect(() => {function tick() {savedCallback.current();}if (delay !== null) {let id = setInterval(tick, delay);return () => clearInterval(id)}}, [delay])
    }
    useInterval(() => {if (pageState === 'insertSms' && addFormSmsCoolDown > 0) {setAddFormSmsCoolDown(addFormSmsCoolDown - 1)}}, 1000)

    // 4b. Send SMS (req №3)
    const [reqAuthSms, setReqAuthSms] = useState(false)
    const smsAuthRe = async () => {if (pageState === 'insertSms' && addFormSmsLimit < 4 && addFormSmsCoolDown === 0) {setReqAuthSms(false);setAddFormSmsCoolDown(60);setAddFormSmsLimit(3);smsAuth()}}
    const smsAuth = async () => {
        if (!reqAuthSms && pageState === 'insertSms' && stringReplaceMask(addFormSms).length === 4) {
            setPageState('loader')
            if (addFormSmsCoolDown > 0) {
                setReqAuthSms(true)
                if (identity !== 0) {
                    try {
                        let ajPost = await req({method: apiUrl.aUrl, rType: 'GET', noSearch: false, gets: {identity: identity, code: addFormSms}})

                        if ([-10, -16, -19].indexOf(ajPost.code) > -1 && ajPost.token) {
                            setToken(ajPost.token)
                            sSet('token', ajPost.token)
                            sSet('code', ajPost.code)
                        } else {
                            clearStorage()
                        }

                        if (ajPost.code === 0) {
                            setPageState('loader')
                            clearStorage()
                            if (!ajPost.redirect) {
                                apiAlert(200,{message: 'redirect is empty in answer (aUrl)'}).then()
                            } else {
                                let redirectUrl = decodeURIComponent(ajPost.redirect)
                                redirectToUrl(redirectUrl)
                            }
                        } else if (ajPost.code === -10) {
                            setOffers(ajPost.products)
                            setPageState('ussd')
                        } else if (ajPost.code === -16 || ajPost.code === -19) {
                            setOffers(ajPost.products)
                            setPageState('link')
                        } else if (ajPost.code === -8) {
                            setPageState('errorMoney')
                        } else if (ajPost.code === -13) {
                            setPageState('errorTariff')
                        } else if (ajPost.code === -3) {
                            setAddFormSmsLimit(addFormSmsLimit - 1)
                            setReqAuthSms(false)
                            setPageState('insertSms')
                        } else if (ajPost.code) {
                            setErrMessage(ajPost.message)
                            setPageState('error')
                            clearStorage()
                        } else {
                            setPageState('notFound')
                            clearStorage()
                        }
                    } catch (e) {
                        apiAlert(200,{target: 'catch - aUrl', error: e}).then()
                        setPageState('error')
                        clearStorage()
                    }
                    setAddFormSms('')
                } else {
                    apiAlert(200,{message: 'identity is zero in sms auth'}).then()
                    setPageState('notFound')
                    clearStorage()
                }
            } else {
                setReqAuthSms(false)
                setAddFormSms('')
                setPageState('insertSms')
            }
        }
    }

    // 5. Auth Tel (req №2)
    const [reqAuth, setReqAuth] = useState(false)
    const phoneAuth = async () => {
        if (!reqAuth && pageState === 'insertPhone' && stringReplaceMask(addFormTel).length === 12) {
            setReqAuth(true)
            setPageState('loader')
            if (identity !== 0) {
                try {
                    let ajPost = await req({method: apiUrl.bSms, rType: 'GET', noSearch: false, gets: {identity: identity, phone: stringReplaceMask(addFormTel)}})

                    if (ajPost.code === 0) {
                        sSet('phone', stringReplaceMask(addFormTel))
                        setPageState('insertSms')
                    } else if (ajPost.code) {
                        setErrMessage(ajPost.message)
                        setPageState('error')
                        clearStorage()
                    } else {
                        setPageState('notFound')
                        clearStorage()
                    }
                } catch (e) {
                    apiAlert(200,{target: 'catch - bSms', error: e}).then()
                    setPageState('error')
                    clearStorage()
                }
            } else {
                apiAlert(200,{message: 'identity is zero in phone auth'}).then()
                setPageState('error')
                clearStorage()
            }
        }
    }

    // 6. Init (req № 1)
    const [reqInit, setReqInit] = useState(false)
    const clickAjaxPreLoad = async () => {
        if (!reqInit && pageState === 'loader' && identity === 0) {
            setReqInit(true)
            let clientId = uSearch('client_id')
            let redirectUrl = uSearch('redirect_uri')
            if (clientId !== '') {
                try {
                    let ajGet = await req({method: apiUrl.nInit, rType: 'GET', gets: ['client_id','redirect_uri','state']})

                    if (ajGet.code === 0) {
                        if (!ajGet.identity) {
                            apiAlert(200,{message: 'identity is empty in answer (nInit)'}).then()
                            setPageState('notFound')
                            clearStorage()
                        } else {
                            sSet('identity', ajGet.identity)
                            sSet('redirect_uri', redirectUrl)
                            redirectToUrl((document.location.origin) + '?identity=' + ajGet.identity)
                        }
                    } else {
                        setPageState('notFound')
                        clearStorage()
                    }
                } catch (e) {
                    apiAlert(200,{target: 'catch - nInit', error: e}).then()
                    setPageState('error')
                    clearStorage()
                }
            } else {
                // apiAlert(200,{message: 'clientId is empty in url'}).then()
            }
        }
    }
    if (!reqInit && pageState === 'loader' && identity === 0) {clickAjaxPreLoad()}

    // 7. JSX → Err Forms
    const ErrT = (props = {}) => {let outVal = 'Внимание!';if (props.message) {outVal = props.message;}return <h2 className="title">{outVal}</h2>}
    const ErrH = (props = {}) => {let outVal = '';if (props.message) {outVal = props.message;}return <p className="text text_large" dangerouslySetInnerHTML={{ __html: outVal}} />}
    const ErrM = (props = {}) => {let outVal = 'Произошла ошибка';if (props.message && props.message !== errMessage) {outVal = props.message;}return <div className="error error__marginTop">{outVal}<br/>Подробнее Т: 707<br/></div>}
    const ErrL = (props = {}) => {let outVal = 'Ок';if (props.message) {outVal = props.message;}return <form className="form"><div className="button form__submit" onClick={(e) => logOut(e)}>{outVal}</div></form>}

    // 8. Service func
    function redirectToUrl(url) {location.href = url}
    const logOut = () => {clearStorage();setReqInit(false);setReqAuth(false);setReqAuthSms(false);setReqService(false);setAddFormSmsLimit(3);setPageState('loader')}
    function onSubmit(e) {e.preventDefault();if (pageState === 'insertPhone') {phoneAuth()} else if (pageState === 'insertSms') {smsAuth()}}
    function clearStorage() {sDel('phone');sDel('token');sDel('code');sDel('redirect_uri')}
    function uUrl (url) {if (url.split('https://')[1]) {url = url.split('https://')[1];}if (url.slice(-1) === '/') {url = url.slice(0, -1);}return url}

    // 4c
    if (pageState === 'insertSms' && addFormSms.length === 0) {placeholderSms = 'Неверный код.';if (addFormSmsLimit === 3) {placeholderSms = ' Введите пин код'} else if (addFormSmsLimit === 2) {placeholderSms += ' Осталось 2 попытки'} else if (addFormSmsLimit === 1) {placeholderSms += ' Осталась 1 попытка'}}

    // 1.4. clear, resize, ajaxUpdate ↓
    if (document.location.hash === '#postmessage') {
        const [resizeValue, setResizeValue] = useState(800)
        function heightSend () {
            let actualPageSize = document.body.clientHeight + 40
            if (resizeValue !== actualPageSize) {
                setResizeValue(actualPageSize)
                window.parent.postMessage({height: actualPageSize}, '*')
            }
        }
        const [resizeEvent, setResizeEvent] = useState(false)
        if (!resizeEvent) {
            setResizeEvent(true)
            window.addEventListener('resize', function(event) {heightSend()}, true)
        }
        setTimeout(() => {heightSend()}, ajaxUpdate)
    }
    // 1.4. clear, resize, ajaxUpdate ↑

    return (
        <div className="App">
            <header className="site-header"><h1 className="site-header__title"><div className="img_svg_logos" />Safe Kids</h1></header>

            {(pageState !== 'loader' && pageState !== 'approveWait') ? '' : (<div className="mainLoader__loaderBlock"><div className="mainLoader"><div className="imgLoad" /></div></div>)}

            {(pageState !== 'insertPhone') ? '' : <div>
                <ErrT message={'Вход'} />
                <form className="form" onSubmit={e => onSubmit(e)}>
                    <label className="form__label"><span className="form__label-text">Введите ваш номер О!</span>
                        <input className="form__input" type="tel" name="phone" value={addFormTel} onChange={(e) => insFormTel(e.target.value, true)} autoFocus="autofocus"/>
                    </label>
                    <p className="form__text">
                        Используйте
                        <span className="button button_text" onClick={(e) => setOnePopup(true)}>единый номер</span>
                        телефона на родительском устройстве и на устройстве контролируемого ребёнка
                    </p>
                    <br />
                    <div className="button form__submit" disabled={stringReplaceMask(addFormTel).length !== 12} onClick={(e) => phoneAuth(e)}>Получить SMS с кодом</div>
                </form>
                {!onePopup ? '' : (
                    <div className="popup" style={{display: "block"}}>
                        <p className="popup__text">Введите номер, на котором подключена услуга O! Safe Kids</p>
                        <button className="popup__btn" onClick={(e) => setOnePopup(false)}>Закрыть</button>
                    </div>
                )}
            </div>}

            {(pageState !== 'insertSms') ? '' : (<div>
                <ErrT message={'На номер '+insFormTel(addFormTel, false)+' отправлено SMS с кодом для входа'} />
                <form className="form" onSubmit={e => onSubmit(e)}>
                    <label className="form__label"><span className="form__label-text">Введите код из SMS</span>
                        <input className={((addFormSmsLimit < 3) ? 'form__input-red' : 'form__input')} type="tel" name="phone" value={addFormSms} onChange={(e) => ((addFormSmsLimit > 0) ? setAddFormSms(stringReplaceMask(e.target.value)) : '')} maxLength="4" autoFocus="autofocus" placeholder={placeholderSms}/>
                    </label>
                    {addFormSmsLimit === 0 ? (<p className="form__text form__text_center container__textPaddingBottom" id="limit_exceeded">Вы превысили максимальное количество попыток ввода!</p>) : ''}
                    {addFormSmsCoolDown === 0 ? (<div className="button button_secondary button_hidden form__submit" type="button" onClick={(e) => smsAuthRe(e)}>Отправить SMS с новым кодом</div>) : (
                        <div>
                            {addFormSmsLimit === 0 ? '' : (<div className="button form__submit" disabled={stringReplaceMask(addFormSms).length !== 4} onClick={(e) => smsAuth(e)}>Подтвердить</div>)}
                            <p className="form__text form__text_center" id="timer_text" style={{display: "block"}}>
                                Повторная отправка SMS<br/>станет возможна через<br/><i className="icon"><div className="img_svg_clock" /></i>&nbsp;<span className="time">{addFormSmsCoolDown}</span>&nbsp;секунд
                            </p>
                        </div>
                    )}
                </form>
            </div>)}

            {(pageState !== 'notFound') ? '' : <div className="notFound">404</div>}

            {(pageState !== 'link') ? '' : (<div><ErrT message={'Услуга не активирована'} />{
                (!!offers && offers.length > 0) ? (
                    offers.map((item, i) => {
                        return <div className="container">
                            <h3 className="service-name container__textVertical"><i className="icon icon-money"><div className="img_svg_datas" /></i><div className="service__name-text">{item.title}</div></h3>
                            <button className="button button_m_top container__textVertical" onClick={(e) => serviceActivate(item.serviceId)}>Активировать</button>
                        </div>
                    })
                ) : <div><ErrT /><ErrH message={'Нет доступных предложений для подключения <a class="button button_text button_text_fwn" href="' + PARTNER_OPT.lk + '" target="blank">' + uUrl(PARTNER_OPT.lk) + '</a>'} /><ErrL /></div>
            }</div>)}

            {(pageState !== 'ussd') ? '' : (<div><ErrT message={'Услуга не активирована'} />{
                (!!offers && offers.length > 0) ? (
                    offers.map((item, i) => {
                        return <div className="container">
                            <h3 className="service-name container__textVertical"><i className="icon icon-money"><div className="img_svg_datas" /></i>{item.title}</h3>
                            <p className="text container__textVertical">Для активации услуги вам нужно отправить с вашего телефона USSD команду:</p>
                            <p className="ussd-command container__textVertical"><div className="service__name-text">{item.ussd}</div><i className="icon"><div className="img_svg_phone" /></i></p>
                        </div>
                    })
                ) : <div><ErrT /><ErrH message={'Нет доступных предложений для подключения <a class="button button_text button_text_fwn" href="'+PARTNER_OPT.lk+'" target="blank">'+uUrl(PARTNER_OPT.lk)+'</a>'} /><ErrL /></div>
            }</div>)}

            {(pageState !== 'errorMoney') ? '' : (<div><ErrT message={'Недостаточно средств!'} /><ErrH message={'Уважаемый абонент, услуга <strong>O! Safe Kids</strong> не&nbsp;оплачена. Пожалуйста, пополните баланс.'} /><ErrM /><ErrL message={'Повторить'} /></div>)}
            {(pageState !== 'errorMoneyTariff') ? '' : (<div><ErrT /><ErrH message={'На Вашем тарифном плане данный сервис не&nbsp;предоставляется. Выберите другой тариф на сайте <a class="button button_text button_text_fwn" href="' + PARTNER_OPT.lk + '" target="blank">' + uUrl(PARTNER_OPT.lk) + '</a>'} /><ErrM /><ErrL /></div>)}
            {(pageState !== 'errorTariff') ? '' : (<div><ErrT message={'Недостаточно средств!'} /><ErrH message={'Уважаемый абонент, Ваш тариф не&nbsp;оплачен. Пожалуйста, пополните баланс.'} /><ErrM /><ErrL message={'Повторить'} /></div>)}
            {(pageState !== 'error') ? '' : (<div><ErrT /><ErrM message={errMessage} /><ErrL /></div>)}

        </div>
    )
}

export default App
